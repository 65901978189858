import Defs from '../../../../../../includes/primitives/colorDefs'

export default {
  data: () => ({
    eqStyle: {
      Status: [ 
        { code: 'MR'      , colName: 'STATUS_CODE', nameShort: 'MR'         , name: 'MR Received'         , width: 80 , bColor: '#e3e3e3', sColor: '#C5CAE9', tColor: '#333', opacity: 1, },
        { code: 'LOA'     , colName: 'STATUS_CODE', nameShort: 'LOA'        , name: 'LOA Issued'          , width: 75 , bColor: '#9693fc', sColor: '#303F9F', tColor: '#fff', opacity: 1, },
        { code: 'PO'      , colName: 'STATUS_CODE', nameShort: 'PO'         , name: 'PO Issued'           , width: 70 , bColor: '#ffc107', sColor: '#FFC107', tColor: '#333', opacity: 1, },
        { code: 'DELIVERY', colName: 'STATUS_CODE', nameShort: 'Delivery'   , name: 'Delivery to Job Site', width: 105, bColor: '#64d969', sColor: '#66BB6A', tColor: '#333', opacity: 1, },
        { code: 'INSTALL' , colName: 'STATUS_CODE', nameShort: 'Install'    , name: 'Installation'        , width: 73 , bColor: '#959595', sColor: '#616161', tColor: '#fff', opacity: 1, },
        { code: 'NOT'     , colName: 'STATUS_CODE', nameShort: 'Not started', name: 'Not started'         , width: 75 , bColor: '#ffffff', sColor: '#BDBCBC', tColor: '#333', opacity: 1, },
      ],
      Border: [
        {
          type: 'MEGA',
          shape: 'ellipse',
          width: 35,height: 15,
          color: 'gray',
          stroke: .2,
          textSize: 10,
          padding: 3,
          rx: 10,ry: 4.5,
        },
        {
          type: 'UNIT',
          shape: 'rect',
          width: 30,height: 14,
          color: 'gray',
          stroke: .2,
          textSize: 10,
          padding: 2.5,
        },
      ],
      LoadedTextProps: {
        x: 6.5,
        y: 6.5,
        color: '#757575',
        bColor: '#efefef',
        textSize: 8.5,
        padding: 1,
      },
      CircleBorder: {
        rx: 6.5,
        ry: 6.5,
        color: '#757575',
        bColor: '#efefef',
        stroke: .5,
        textSize: 8.5,
        padding: 1,
      },
      tagControl: {
        labelWidth: 20,
        on: {
          bcolor: '#4FBE79',
          tcolor: '#fff',
          textx: 26,
          texty: 10,
          slidex: 51,
          slidey: 9,
        },
        off: {
          bcolor: '#D3D3D3',
          tcolor: '#707070',
          textx: 37,
          texty: 10,
          slidex: 29,
          slidey: 9,
        }
      },
      critical: [ 
        { code: 'P2W', colName: 'CRITICAL', nameShort: 'P2W', name: 'On-site delivery over 2 weeks from ROS'  , tcolor: '#ec407a' },
        { code: 'W2W', colName: 'CRITICAL', nameShort: 'W2W', name: 'On-site delivery within 2 weeks from ROS', tcolor: '#39b1e6' },
        { code: 'NMR', colName: 'CRITICAL', nameShort: 'NMR', name: 'Not the MR Item'                         , tcolor: '#e99709' },
        { code: 'NPO', colName: 'CRITICAL', nameShort: 'NPO', name: 'Not the PO item'                         , tcolor: '#65b738' },
      ],
      search: {
        over: {
          stroke: '#333',
          fill: '#fff',
          color: '#fff'
        },
        out: {
          stroke: '#bcbcbc',
          fill: '#fff',
          color: '#c9c9c9'
        },
        selected: {
          stroke: '#1565c0',
          fill: '#64b5f6',
          color: '#757575'
        }
      },
      legend: {
        labelx1: 20,
        labelx2: 30,
      }
    }
  }),
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}